@import url('https://fonts.googleapis.com/css?family=Poppins:wght@400,600');

html {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    margin-left: auto;
    margin-right: auto;
    background: #e6e7e7; /* #F6F1EB - more beige look, #e6e7e7 - grey */
}


.box, p {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

body {
    margin: 0px;
}

.logo-parent {
    display: flex;
    justify-content: center;
}

.logo {
    width: 150px;
    text-align: center;
}

.box {
    width: 100%;
    border-radius: 28px;
    /* box-shadow: 0px -5px 13px #00000025; */

    text-align: center;
    position: relative;

    /* for laptops */
    max-width: 500px;
    margin: 0px auto;

}

.select-box {
    /* height: 250px; */
    padding-bottom: 10px;
}


.box hr {
    width: 80%;
    border: 1px solid #00000010;

    margin: 7px auto;
}

.box div {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;   

    border: none;
    background: none;
    font-size: 28px;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 600;
}

/*
.select-box div:first-child {
    margin-top: 10px;
    transform: translateY(3px);
} */

.select-box div:nth-child(2) {
    transform: translateY(1px);
}

.grid-item {
    margin-top: 10px !important;
}

#select-box-1 {
    background: none; /* #f1f1f1 */
    box-shadow: none;
}

.bg-box-parent {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;

    filter: drop-shadow(0px -5px 13px #00000035);
}

.shadow-filter {
    filter: drop-shadow(0px -5px 13px #00000035);
}

#bg-box-1 {
    background: #f1f1f1;
    height: 300px;
    position: absolute;
    box-shadow: none;
}

.bg-box-2 {
    height: 250px;
    position: absolute;
}

#select-box-2 {
    /* background: #F8E19D; */
    transform: translateY(-10px);
    box-shadow: none;

    /* transition: background 1.5s !important; */
}

.triangle {
    height: 30px;
    width: 30px;
    background: #f1f1f1;
    position: absolute;
    transform: scaleX(1.2) translateY(-8px) rotate(45deg);
}

.box-papers {
    /* background: #FFC001; */
    transform: translateY(-10px);
    margin: 0px auto 0px auto;
    padding-bottom: 60px;
}

@media only screen and (min-width: 500px) {
    .box-papers {
        padding-bottom: 0px;
        margin-bottom: 80px;
    }
}


div .container {  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Year paper-1 paper-2 paper-3"
        "Done paper-1 paper-2 paper-3";

    background: #00000020;
    border-radius: 20px;
    margin: 10px;
    /* width: 94%; */
    max-width: 320px;
    color: white;
    /* padding-bottom: 15px; */
    margin-left: auto;
    margin-right: auto;
}

.Year { 
    grid-area: Year; 
    margin-top: 5px;
}

.Done { 
    grid-area: Done;
    margin-top: 8px;
}

.Done img {
    width: 40px;
    margin-top: -4px;
}

.paper-1 { grid-area: paper-1; }

.paper-2 { grid-area: paper-2; }

.paper-3 { grid-area: paper-3; }


.paper {
    position: relative;
    margin-top: 10px;
}

.paper div {
    width: 60px;
    height: calc(1.41 * 60px);
    background: white;
    border-radius: 10px;
    position: absolute;

    box-shadow: 2px 2px 3px #00000025;
    cursor: pointer;
}

.paper div:first-child {
    z-index: 30;
}

.paper div:nth-child(2) {
    transform: translate(3px, 3px);
    z-index: 20;
}

.paper div:nth-child(3) {
    transform: translate(6px, 6px);
    margin-bottom: 10px;
    z-index: 10;
}

.paper div:first-child span, .paper div:nth-child(2) span {
    font-size: 18px;
    line-height: 15px;
    color: orange;
    text-align: left;
    display: block;
    transform: rotate(-90deg) translate(-35px, 7px);
}

.nav {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 70px;
}

.nav-colour {
    z-index: 1100;
    background-color: black;
    /* opacity: 0.5 */
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 70%);
}

.nav-blur {
    z-index: 1101;
    backdrop-filter: blur(10px);
}

.nav-text {
    z-index: 1102;
    color: white;
    text-align: center;
}

a {
    text-decoration: none !important;
}

.papertype-name {
    font-size: 23px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    height: 70px;
}


.flex-item {
    transform: translateY(3px);
    padding: 10px;
}

.flex-item:nth-child(1) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.flex-item:nth-child(2) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.flex-item:nth-child(3) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.nav-img {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.nav-img img {
    width: 32px;
}


.paper-pill {
    width: 100%;

    background: #00000020 !important;
    border-radius: 20px;
    margin: 10px;

    max-width: 320px;
    color: white;

    margin-left: auto;
    margin-right: auto;

    display: inline-flex;

    overflow-x: scroll;
}

.paper-pill .ppr:first-child {
    margin-left: 75px;
}
.paper-pill .ppr:nth-last-child(4) {
    margin-right: 47px;
}



.box-papers {
    /* overflow-x: hidden;
    overflow-y: visible !important; */
}

.content {
    overflow-x: hidden;
    padding-top: 20px;
    margin-top: -23px;
}


.papers-slide-parent {
    width: 100%;

    display: inline-flex;
    justify-content: center; /* flex-start */ /* space-evenly */

    overflow-x: scroll;
    overflow-y: hidden !important;

    height: 119px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    margin-top: 8.5px; /* Bookmarks page */
    margin-top: 10.5px; /* All Exams page */
}

@media only screen and (min-width: 400px) {
    .papers-slide-parent {
        justify-content: center; /* space-evenly */
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.papers-slide-parent::-webkit-scrollbar {
    display: none;
}



.paper-pill-2-around {
    width: 100%;
    position: absolute;
    margin: 0px auto !important;
}


.paper-pill-2 {
    position: absolute;
    height: 100px;

    background: #00000020 !important;
    border-radius: 20px 20px 0px 0px;

    width: 81% !important;
    left: 5% !important;
    
    transform: translate(-3px, 29.5px);  /* Bookmarks page */
    transform: translate(-2px, 29.5px);  /* All Exams page */

    padding-bottom: 0px;

    display: inline-flex;

}

.bmk-open-box .paper-pill-2 {
    width: 77% !important;
    left: 4.8% !important;
}



.title-loading {
    animation: fadeIn 1500ms ease-in-out;
}

.fade-in {
    animation: fadeIn 250ms ease-out;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.month-style {
    display: inline-flex;
    border-bottom: 2.5px solid white;
    line-height: 21px;
    font-size: 18px;
    transform: translate(7px, -6.5px) !important;
}

.paper-pill-2 .ppr {
    margin: 0px 3px;
}


.ppr {
    display: inline-block;
    transform: translateY(0px) rotate(21deg);

}

.ppr div { 
    position: relative;
    margin: 7px 5px 7px 5px;
}

.ppr div div { /*formerly .ppr div (only) */
    width: 60px;
    height: calc(1.41 * 60px);
    background: white;
    border-radius: 10px;
    position: relative;

    box-shadow: 2px 2px 3px #00000025;
    margin: 7px 5px 7px 5px;
}

.nav .flex-item {
    opacity: 0.6;
    transition: 0.2s;
    transform-origin: 50% 75%;
}

.nav .active .flex-item {
    opacity: 1;
}

.nav .flex-item:active {
    transform: scale(0.9);
}

.nav .flex-item .nav-img-img {
    height: 20px !important;
    /* width: auto !important; */

    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
    padding: 8px 18px;
}


.nav .flex-item #nav-img-exams {
    background-image: url("./exams-off.svg");
}

.nav .active .flex-item #nav-img-exams {
    background-image: url("./exams-on.svg");
}


.nav .flex-item #nav-img-bookmarks {
    height: 17px !important;
    background-image: url("./bookmark-off.svg");
}

.nav .active .flex-item #nav-img-bookmarks {
    background-image: url("./bookmark-on.svg");
}


.nav .flex-item #nav-img-about {
    height: 17px !important;
    background-image: url("./about-off.svg");
}

.nav .active .flex-item #nav-img-about {
    background-image: url("./about-on.svg");
}

.bookmark-menu {
    height: 30px;
    transform: translate(0px, 3px);
    transition: 100ms;
    transform-origin: 50% 130%;
}

.bookmark-menu:active {
    transform: scale(0.9);
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}

.tick-onoff {
    width: 25px;
    transition: 100ms;
    padding: 14px 18px 13px 17px;
    transform: translate(15px, 0px);
    cursor: pointer;
    border-radius: 0px 0px 19px 0px;
    transform-origin: 70% 50%;
}

.tick-onoff:active {
    transform: scale(0.9) translate(15px, 0px) !important;
}


.ppr div:first-child {
    z-index: 30;
}

.ppr div div:nth-child(2) {    
    transform: translate(3px, -95px);
    z-index: 20;
    position: absolute;
}

.ppr div div:nth-child(3) {    
    transform: translate(6px, -92px);
    z-index: 10;
    position: absolute;
}

.ppr a {
    text-decoration: none;
}

.ppr div span {
    font-size: 18px;
    line-height: 15px;
    color: black;
    text-align: left;
    display: block;
    transform: rotate(-90deg) translate(-35px, 7px);
}

.yr {
    transform: rotate(-90deg) translate(-25px, -25px);
    position: absolute;
    background: #00000050 !important;
    width: 98px;
    height: 44px;
    border-radius: 20px 20px 0px 0px;
    padding-top: 5px;
    z-index: 100;
    /* backdrop-filter: blur(10px); */
}

.month {
    font-size: 14px !important;

    transform: rotate(-90deg) translate(-38px, 11px);
    position: absolute;
    background: #00000020 !important;
    width: 98px;
    height: 24px;
    z-index: 100;
    /* backdrop-filter: blur(10px); */
}

.tick {    
    transform: rotate(90deg) translate(27.5px, -249px);
    position: absolute;
    background: #ffffff50 !important;
    width: 98px;
    height: 44px;
    border-radius: 20px 20px 0px 0px;
    z-index: 100;
    /* backdrop-filter: blur(10px); */
}

.tick img {
    width: 25px;
    transform: rotate(-90deg) translate(-3px, 1px);
}

.dropdown-img {
    width: 20px;
    opacity: 0.5;
    transform: translate(3px, -3px);
}

.qual-select {
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.qual-title-papers {
    color: white !important;
    background: #00000020 !important;
    border-radius: 25px 25px 0px 0px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 23px !important;
}

.bmk-open-box {
    width: 95%;
    margin: 0px auto 20px auto;
    padding: 90px 0px 5px 0px;
    border-radius: 20px 20px 30px 30px;
    margin-top: -83px;
}

.papertype-box {
    background: #00000019 !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    margin-bottom: 20px;
    padding-bottom: 1px;
}

.papertype-box:nth-child(odd) {
    background: #00000019 !important;
}

.papertype-box:nth-child(even) {
    background: #00000029 !important;
}



.box-papers {
    padding-top: 15px;
}

.circle {
    width: 10px; 
    height: 10px;
}

.circle-orange {
    background-color: orange;
}

.circle-red {
    background-color: red;
}

.dropdownDisabled {
    color: grey;
}

.how-to {
    position: relative;
    text-align: center;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    width: 290px; /* 200px */
    margin: 20px auto 0px auto;
    padding-bottom: 30px;
    opacity: 0.6;

    font-size: 25px !important;
    line-height: 29px;
}

.loading {
    margin: 40px auto 0px auto;
    text-align: center;
    font-size: 15px;
}


@keyframes spin {
    from {
        transform:rotate(0deg) scale(1); /* scale(0.5)*/
        opacity: 1; /* opacity: 0*/
    }
    to {
        transform:rotate(360deg) scale(1);
        opacity: 1;
    }
}


.spinning {

    animation: spin 650ms ease-in-out infinite;
    animation-timing-function: cubic-bezier(.76,.21,0,1);
}

.choice-div {
    margin-bottom: 20px !important;
}

.choice-div-papertypes {
    margin-top: 0px !important;
}


.qual-title {
    font-size: 20px !important;
    user-select: none;
    color: grey;

    filter: drop-shadow(0px 5px 4px #00000015);
}


.qual-title-pink {
    background-color: #f6aded !important; /* grey; */
    color: black;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0px auto;
}

.subj-title {
    background-color: black !important; /* grey; */
    color: white;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 13px 5px 28px;
    margin: 0px auto;
}

.board-title {
    background-color: #ffffff70 !important; /* grey; */
    color: #444;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0px auto;
}

.allpapers-title {
    background-color: #00000080 !important; /* grey; */
    color: #f5f5f5;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0px auto;
}

.papertype-title {
    background-color: #ffffff70 !important; /* grey; */
    color: #444;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0px auto;
}

.papertype-title-allpapers {
    background-color: #ffffffc0 !important; /* grey; */
}



.subj-circle {
    color: pink;
    font-size: 35px;
    position: absolute;
    transform: translate(-19px, -11px);
}

.subj-circle-title {
    font-size: 50px;
    transform: translate(-30px, -17px);
}

.grid-container {
}

.grid-item {
    display: inline-grid;
    margin: 0px 5px;
}

html {
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    -webkit-tap-highlight-color: transparent;
}

.bookmark-box-behind {
    position: absolute;

    width: 95%;
    left: 2.5%;

    height: 70px;
    background: white !important;
    border-radius: 20px;
    margin: 0px auto;
    margin-bottom: 15px;

    filter: drop-shadow(0px 5px 4px #00000015);
}


.bookmark-box {
    user-select: none;

    width: 95%;
    height: 70px;
    background: white;
    border-radius: 20px;
    margin: 0px auto;
    margin-bottom: 15px;

    position: relative;

    cursor: pointer;
}


.bookmark-box-together {

    transition: 100ms;
    transform-origin: 50% 50%;

}

.bookmark-box-together:active {
    transform: scale(0.98);
}
/*
.logo {
    transition: 500ms;
}

.logo:active {
    transform: rotate(-360deg) scale(0);
} */

.bookmark-box:first-child {
    margin-top: 20px !important;
}


.bookmark-box p {
    font-size: 20px;
}

.bookmark-box .bmk-title {
    width: calc(100% - 80px);
    float: right;
    height: 70px;
}



.bookmark-box .qual {
    font-size: 14px !important;

    transform: rotate(-90deg) translate(-20.5px, -20.5px);
    position: absolute;
    width: 70px;
    height: 24px;
    border-radius: 20px 20px 0px 0px;
    padding-top: 5px;
    z-index: 100;

    color: #ffffffd0;
    color: black;
    /* backdrop-filter: blur(10px); */
}

.bookmark-box .qual-dark {
    background: #00000015 !important;
}

.bookmark-box .subject {
    font-size: 14px !important;

    transform: rotate(-90deg) translate(-23px, 6px);
    position: absolute;
    /* background: #00000020 !important; */
    width: 70px;
    height: 24px;
    z-index: 100;
    /* backdrop-filter: blur(10px); */
}


.bookmark-box .exam-board {
    font-size: 14px !important;

    transform: rotate(-90deg) translate(-23px, 30px);
    position: absolute;
    /* background: #00000010 !important; */
    width: 70px;
    height: 24px;
    z-index: 100;
    /* backdrop-filter: blur(10px); */
}

.linky {
    font-style: italic;
    text-decoration: underline;
    color: #fd3ae6;
    cursor: pointer;
}


.papertype-flex {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    transform: translateX(-15px);
}

.papertype-flex-item:nth-child(1) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    transform: translateY(0px);
}

.papertype-flex-item:nth-child(2) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    transform: translateY(-3px);
}


.remove-bmk-button {    
    display: block;
    width: fit-content;
    margin: -15px auto 0px auto;
    padding: 0px 10px 8px 15px;
    cursor: pointer;
    opacity: 0.4;
}




.cookies-message, .CookieConsent {
    display: none; /*block*/

    position: fixed;
    bottom: 15px;
    background: #000000c0;
    opacity: 1;
    width: 80%;
    border-radius: 30px;
    padding: 20px;
    padding-top: 15px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    z-index: 10000;
    backdrop-filter: blur(10px) !important;
    filter: drop-shadow(0px -5px 13px #00000035);
}

.cookies-message h1 {
    display: none;

    color: white;  
    font-size: 29px;  
    line-height: 20px;
}

.cookies-message p {
    color: #999;  
    font-size: 19px;  

    font-size: 28px;  
    line-height: 30px;  
    font-weight: bold;  
    margin: 10px;
    margin-bottom: 20px;
}

.cookies-buttons, .react-cookie-law-manage-btn, .react-cookie-law-accept-btn, .react-cookie-law-save-btn {
    display: inline-flex !important; /* flex */
    justify-content: space-evenly !important;

    align-items: center;
}

.cookies-message button, .react-cookie-law-manage-btn, .react-cookie-law-accept-btn, .react-cookie-law-save-btn {
    border: 4px solid white;
    background: white !important;
    color: black !important;
    border-radius: 100px;
    height: 60px;
    width: 130px !important;
    font-size: 17px !important;  
    line-height: 18px;
    font-weight: bold !important;
    font-family: inherit;
    cursor: pointer !important;
    transition: 100ms;

    white-space: normal !important;
}

.cookies-message button:active, .react-cookie-law-manage-btn:active, .react-cookie-law-accept-btn:active, .react-cookie-law-save-btn:active {
    transform: scale(0.95);
}

.cookies-message button:first-child, .react-cookie-law-manage-btn:first-child, .react-cookie-law-accept-btn:first-child, .react-cookie-law-save-btn:first-child {
    background: none !important;
    color: white !important;
}

.cookie-emoji {
    filter: drop-shadow(0px 0px 4px #ffffff);
}


@media only screen and (min-width: 500px) {

        /*
    .cookies-message {
        width: 80%;
    } */

    .cookies-message p, .react-cookie-law-msg {
        text-align: center;
        /*
        margin-left: auto;
        margin-right: auto;
        width: 400px; */
    }

    .cookies-buttons, .react-cookie-law-manage-btn, .react-cookie-law-accept-btn  {
        width: 300px;
        margin: 0px auto;
    }
}


.exams-flex {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}



.centrebar {
    width: 450px;
}

.sidebar {
    display: none; /* always none */
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: grey
}

.sidebar img {
    width: 200px;
}


@media only screen and (min-width: 880px) {
    .sidebar {
        display: none; /* No Ads: none, With Ads: block */
    }
}



@media only screen and (min-width: 1050px) {

    .centrebar {
        width: 500px;
    }

    .sidebar, .sidebar img {
        width: 250px;
    }
}

.about-page {
    overflow: hidden;
    margin: 0px auto;
}

.about-page h2 {
    font-size: 50px;
    text-align: center;
    margin: 45px 0px 40px 0px;
    line-height: 52px;
}


.paper-notice {
    text-align: center; 
    width: 200px; 
    margin: 30px auto 0px auto;
    line-height: 18px;
    font-size: 600;
    color: #333;
    opacity: 0.5;
    margin-top: 15px;
}

#iphone-mockup {
    display: block;
    width: 250px;
    margin: 0px auto;
    filter: drop-shadow(5px 5px 5px #00000035);
}

#mockup-bookmarks {
    display: block;
    width: 290px;
    margin: 0px auto;
    /* filter: drop-shadow(5px 5px 5px #00000035); */
}

#mockup-pf {
    display: block;
    width: 250px;
    margin: 0px auto;
    /* filter: drop-shadow(5px 5px 5px #00000035); */
    margin-top: 20px;
}


.about-page .square {
    display: none;

    width: 130px;
    height: 200px;
    background: red;
    margin: 0px auto;
    transform: rotateX(10deg) rotateY(-25deg) rotateZ(20deg);
}


.falling-papers-parent {
    position: relative;
    width: 300px;
    height: 206px;
    margin: 0px auto;
}

.falling-papers {
    margin: -50px auto 0px auto;
    width: 300px;
    position: absolute;
}




.ppr2 {
    transform: translateY(0px) rotate(21deg);
    margin: none;
}

.ppr2 div { 
    position: relative;
    margin: 7px 5px 7px 5px;

    height: 0px;
    width: 0px;

}

.ppr2 div div { /*formerly .ppr div (only) */
    width: 60px;
    height: calc(1.41 * 60px);
    background: white;
    border-radius: 10px;
    position: relative;

    box-shadow: 2px 2px 3px #00000025;
    margin: 7px 5px 7px 5px;
}

.ppr2 div:first-child {
    z-index: 30;
}

.ppr2 div:nth-child(2) {
    z-index: 40;
    left: 50px;
}

.ppr2 div div:nth-child(2) {    
    transform: translate(3px, -95px);
    z-index: 20;
    position: absolute;
}

.ppr2 div div:nth-child(3) {    
    transform: translate(6px, -92px);
    z-index: 10;
    position: absolute;
}

.ppr2 a {
    text-decoration: none;
}

.ppr2 div .text {
    font-size: 18px;
    line-height: 15px;
    color: black;
    text-align: left;
    display: block;
    transform: rotate(-90deg) translate(-35px, 7px);
}

#p1 {
    margin-left: -35px;
    margin-top: 25px;
    
    transform: rotateX(-5deg) rotateY(30deg) rotateZ(38deg) scale(0.4) !important;

    z-index: 50;
}

#p2 {
    margin-left: 40px;
    margin-top: -20px;
    
    transform: rotateX(-5deg) rotateY(30deg) rotateZ(20deg) scale(0.45) !important;
    z-index: 51;
}


#p3 {
    margin-left: -65px;
    margin-top: 30px;

    transform: rotateX(-5deg) rotateY(30deg) rotateZ(38deg) scale(0.5) !important;
    z-index: 52;
}

#p4 {
    margin-left: -0px;
    margin-top: -10px;

    transform: rotateX(-5deg) rotateY(30deg) rotateZ(28deg) scale(0.55) !important;
    z-index: 52;
}

#p5 {
    margin-left: 65px;
    margin-top: -10px;

    transform: rotateX(-5deg) rotateY(30deg) rotateZ(13deg) scale(0.52) !important;
    z-index: 53;
}


#p6 {
    margin-left: -80px;
    margin-top: 15px;

    transform: rotateX(-5deg) rotateY(30deg) rotateZ(28deg) scale(0.64) !important;
    z-index: 54;
}

#p7 {
    margin-left: -30px;
    margin-top: 50px;

    transform: rotateX(-5deg) rotateY(30deg) rotateZ(38deg) scale(0.72) !important;
    z-index: 55;
}

#p8 {
    margin-left: 70px;
    margin-top: -40px;

    transform: translate3d(0, 0, 50px) rotateX(-5deg) rotateY(0deg) rotateZ(20deg) scale(0.8) !important;
    z-index: 56;
}


#p9 {
    margin-left: 140px;
    margin-top: 10px;

    transform: rotateX(-5deg) rotateY(0deg) rotateZ(40deg) scale(0.65) !important;
    z-index: 57;
}


#big-p1 {
    transform:  translate3d(0, 0, 100px) rotateX(0deg) rotateY(0deg) rotateZ(-15deg)  scale(1.8); /* Translate3d fixed z-index issue on safari */
    z-index: 100;

}

#big-p2 {
    transform: translate3d(-14px, -10px, 200px) rotateX(0deg) rotateY(0deg) rotateZ(10deg) scale(1.8); 
    z-index: 200;

}

#big-p3 {
    transform: translate3d(-17px, 6px, 300px) rotateX(0deg) rotateY(0deg) rotateZ(355deg) scale(1.8);
    z-index: 300;
}




@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

.rocket {
    opacity: 0;
    animation: fadeInUp .7s;
    animation-delay: 500ms;
    animation-fill-mode: forwards;

}

.rounded-box-containter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.about-page .rb-cracks {
    background: rgb(30,59,199) !important;
    background: linear-gradient(156deg, rgb(9,222,138) 0%, rgb(30,59,199) 100%) !important;

    color: white;
}

.about-page .rb-completed {
    background: rgb(157,58,180) !important;
    background: linear-gradient(156deg, rgba(157,58,180,1) 0%, rgba(76,69,252,1) 100%) !important;
    text-align: right;

    color: rgb(99 215 113);
}

.about-page .rb-completed .lighter , .about-page .rb-pf-space .lighter {
    color: #ffffff90;
}

.about-page .rb-completed .completed-below-text {
    text-align: left !important;
    margin-top: 39px !important;
    color: #ffffff80 !important;

}

.about-page .rb-pf-space {
    background: rgb(0,0,0) !important;
    background: linear-gradient(158deg, rgba(0,0,0,1) 0%, rgba(0,0,76,1) 100%) !important;

    color: white;
}

.about-page .rb-completed .lighter , .about-page .rb-pf-space .lighter {
    color: #ffffff90;
}

.about-page .ppr-small {
    transform: rotateX(5deg) rotateY(30deg) rotateZ(35deg) scale(0.5);
}

.about-page .ppr-big-group {
    width: 330px;
    margin: 0px auto;
    margin-top: 21px;
}

.about-page .ppr-big {
    transform: rotateX(5deg) rotateY(20deg) rotateZ(25deg)  scale(1.8);
    margin-right: 20px;
    margin-left: 10px;

}

.about-page .lighter {
    color: #00000065 !important;
}


.about-page .white-lighter {
    color: #ffffff80 !important;
}

.ppr-big div div {

    box-shadow: 0px 0px 5px #00000045;
}

.about-page .ppr-big div span {
    font-size: 18px;
    line-height: 17px;
    color: #333;
    text-align: left;
    font-weight: 600;
    display: block;
    transform: rotate(0deg) translate(0px, 0px);
    padding: 10px 8px;
}


.about-page .ppr-big div span span {
    padding: 0px !important;
}

.shadow-emoji {
    text-shadow: 1px 1px 3px #888;
}

.button-discord {
    background: #5865F2;
    width: 200px;
    height: 70px;
    border-radius: 20px;
    color: white;
    margin: 15px auto 0px auto;

    filter: drop-shadow(0px 5px 13px #00000035);
    
}


.button-email {
    background: red;
    width: 200px;
    height: 70px;
    border-radius: 20px;
    color: white;
    margin: 15px auto 0px auto;

    filter: drop-shadow(0px 5px 13px #00000035);
    
}

.button-pf {
    background: #FEBC2D;
    width: 200px;
    height: 70px;
    border-radius: 20px;
    color: white;
    margin: 15px auto 5px auto;

    text-align: center;
    font-size: 16px;
    position: relative;
    top: 5px;
    font-weight: 400;

    filter: drop-shadow(0px 5px 13px #00000035);

    
}

.button-pf-parent {
}

.button-pf-span {
    display: inline-block;
    transform: translate(10px, -10px);
    width: 106px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
}

.button-discord span {
    text-align: center;
    font-size: 16px;
    position: relative;
    left: 95px;
    top: 5px;
    font-weight: 400;
}

#logo-discord {
    width: 170px !important;
    height: 46px;
    margin: 0px auto;
    margin-top: -15px;
}

.link-pf {
    color: #FEBC2D;
    text-decoration:  underline dotted #FEBC2D70 3px !important;
}

.link-email {
    color: red;
}

.title-fastpapers {
    color: #FFAEEC;
}


/* 
.ppr-big div div {
    width: 80px;
    height: calc(1.41 * 80px);
    background: white;
    border-radius: 10px;

    box-shadow: 2px 2px 3px #00000025;
    margin: 7px 5px 7px 5px;
}
*/


.about-page h4 {
    margin: 20px auto;
    padding: 20px;
    width: 300px;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    line-height: 30px;
    /* transform: translateX(-100px); */

}

.rounded-box {
    /* margin: 0px auto 20px auto; */
    background: white;
    border-radius: 30px;
    padding: 20px;
    width: 300px;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    line-height: 30px;

    filter: drop-shadow(0px 5px 4px #00000015);
}

@supports (-webkit-text-stroke: 1px black) {
    .outline-text  {
      -webkit-text-stroke: 2px black;
      -webkit-text-fill-color: #e6e7e7;
    }
  }

.ppr-crack-parent {
    height: 150px;
    position: relative;
}

.ppr-crack {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 200px;
    height: 105px;
    overflow: hidden;

    border-bottom: solid #ffffff80 1px;
    box-shadow: inset 0 -7px 7px -7px rgba(0,0,0,0.4);

    cursor: default;

    transform: scale(1.3);

    position: absolute;
    bottom: -2px; /* 19px */
    left: 49px; /* 73px */
}

.ppr-crack .ppr {
    margin-top: 34px;
    margin-left: 45px;
}



.ppr-crack .ppr div div {
    filter: drop-shadow(0px 0px 4px #00000025);
}

.ppr-crack .ppr div div:nth-child(1) {
    transform: rotate(-10deg);
}

.ppr-crack .ppr div div:nth-child(2) {
    transform: rotate(5deg) translate(-3px, -120px);
    backdrop-filter: blur(10px);
}

.ppr-crack .ppr div div:nth-child(3) {
    transform: rotate(-3deg)  translate(12px, -132px);
    backdrop-filter: blur(10px);
}

#root3 {
    margin-top: 25px;
}

#root3 svg line {
    fill: none;
    stroke: #f4c248; /* #ffffff; */
    stroke-width: 0;
}

polygon.star { 
    transform-origin: center;
    transform-box: fill-box;
    fill: #f4c248 !important;
    stroke-width: 3 !important;
    stroke: black !important;
    transform: scale(0);
}

@keyframes star-grow-2 {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(2.5);
    }
} 

@keyframes line-grow {
    from {
        stroke-dasharray: 1 var(--L);
    }
    to {
        stroke-dasharray: var(--L) var(--L);
    }
}

.legal-links {
    text-align: center;
    margin: 30px 0px 15px 0px; /* 3px 0px 15px 0px; */
    font-weight: 600;
    color: #aaa;
    font-size: 17px;
}

.legal-links a {
    color: #777;
    padding: 0px 3px;
}

.legal-title {
    font-weight: bold;
    font-size: 160% !important;
}

.react-cookie-law-dialog {
    bottom: 0px;
    top: none;
}

.react-cookie-law-policy {
    position: relative !important;
    bottom: 13px !important;
    text-decoration: underline !important;
}


.flex-container a {
}
